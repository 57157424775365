import { Link } from "gatsby";
import { PropsWithChildren, useState } from "react";
import * as React from "react";

import { Contact } from "../../../../../components/Forms/Contact/Contact";
import ModalForm from "../../../../../components/Modal/ModalForm";
import { Wrapper } from "./styles";

interface Props extends PropsWithChildren<any> {
  desktop?: boolean;
}

const NavbarLinks: React.FC<Props> = (props: Props) => {
  const [is_active, setIsActive] = useState<boolean>(false);
  return (
    <>
      {is_active && (
        <ModalForm
          title={"Solicitar información"}
          from={"Solicita una demo: header"}
          is_active={setIsActive}
          form={Contact}
        />
      )}
      <Wrapper desktop={props.desktop}>
        <Link to="#services">Login</Link>
        <Link
          to="#team"
          className={"a-button"}
          onClick={() => {
            setIsActive(true);
          }}
        >
          Solicitar una demo
        </Link>
      </Wrapper>
    </>
  );
};

export default NavbarLinks;
