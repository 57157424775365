import "styles/index.scss";

import i18next from "i18next";
import * as React from "react";
import { I18nextProvider } from "react-i18next";

import BannerCookies from "../../../components/GTM/BannerCookies";
import global_en from "../../../translations/en/global";
import global_es from "../../../translations/es/global";
import { Footer } from "./footer";
import Header from "./header";

interface Props {
  children: JSX.Element[] | JSX.Element;
  lang: string;
}

const Layout: React.FC<Props> = (props: Props): JSX.Element => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: "es",
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
    },
  });
  // i18next.changeLanguage(props.lang).then(() => {});
  return (
    <>
      <form name="contacto" method="post" data-netlify="true" netlify-honeypot="bot-field" hidden>
        <input type="hidden" name="form-name" value="contacto" />
        <input type="text" name="bot-field" />
        <input type="text" name="from" />
        <input type="text" name="name" />
        <input type="email" name="email" />
        <input type="text" name="company" />
        <input type="text" name="post" />
        <textarea name="message" />
        <input type="checkbox" name="policy" value="Sí" />
      </form>
      <I18nextProvider i18n={i18next}>
        <div id={"circle"} />
        <BannerCookies />
        <Header />
        {props.children}
        <Footer />
      </I18nextProvider>
    </>
  );
};

export default Layout;
