import "./footer.scss";

import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          foundingDate
        }
      }
    }
  `);
  return (
    <footer className="o-footer">
      <div className="c-footer">
        <p className="m-details">
          © {data.site.siteMetadata.foundingDate} | {data.site.siteMetadata.title}
        </p>
        <ul className="m-legals">
          <li>
            <a
              href="https://ingenieriasamat.es/politica-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Aviso legal
            </a>
          </li>
          <li>
            <a
              href="https://ingenieriasamat.es/politica-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Política de privacidad
            </a>
          </li>
          <li>
            <a
              href="https://ingenieriasamat.es/politica-de-cookies/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Cookies
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
