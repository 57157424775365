module.exports = {
  defaultTitle: "WayUp - Software de configuración de ascensores",
  author: "TheCookies Agency",
  siteUrl: "https://wayupelevator.com/",
  legalName: "WayUp",
  defaultDescription: "El primer Configurador web de Ascensores del mercado, hecho por expertos y para todo el Sector del Ascensor",
  logotypeUrl: "",
  socialLinks: {
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    telegram: "",
    vimeo: "",
    youtube: "",
    tiktok: "",
    medium: "",
    spotify: "",
    podcast: "",
  },
  social: {
    facebook: "@",
    twitter: "@",
    linkedin: "@",
    instagram: "@",
    telegram: "@",
  },
  address: {
    streetAddress: "Calle José Pellicer Ossau, 9",
    city: "Zaragoza",
    region: "Zaragoza",
    country: "España",
    zipCode: "50003",
  },
  contact: {
    email: "comunicacion@ingenieriasamat.es",
    phone: "657457658",
  },
  foundingDate: "2022",
};
