import "./contact-form.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { sync_svg } from "./elements_form";

interface PropsForm extends PropsWithChildren<any> {
  handleSubmit: any;
  submitting: boolean;
  success: boolean;
  fail: boolean;
  from?: string;
}

export const Contact = (props: PropsForm) => {
  const { site } = useStaticQuery(graphql`
    query emailAndPhone {
      site {
        siteMetadata {
          email
        }
      }
    }
  `);
  const { email } = site.siteMetadata;
  return (
    <form name="form-ok" method="post" onSubmit={props.handleSubmit} className="m-contact-form">
      <input type="text" name="from" value={`${props.from ? props.from : "Página de inicio"}`} readOnly hidden />
      <label htmlFor={"name"} hidden>
        Nombre
      </label>
      <input
        className={"a-input"}
        type="text"
        name="name"
        id={"name"}
        placeholder="Nombre completo"
        autoFocus
        required
      />
      <label htmlFor={"email"} hidden>
        Email{" "}
      </label>
      <input className={"a-input"} type="email" name="email" id={"email"} placeholder="email@xxx.com" required />
      <label htmlFor={"company"} hidden>
        Nombre de la empresa
      </label>
      <input className={"a-input"} type="text" name="company" id={"company"} placeholder="Empresa" required />
      <label htmlFor={"post"} hidden>
        Cargo
      </label>
      <input className={"a-input"} type="text" name="post" id={"post"} placeholder="Cargo" required />
      <label htmlFor={"message"} hidden>
        Mensaje
      </label>
      <textarea className={"a-input"} name={"message"} id={"message"} placeholder={"Mensaje"} />
      <div className={"a-private"}>
        <label htmlFor="policy">
          {" "}
          Acepto la{" "}
          <a
            href="/legal/politica-de-privacidad/"
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={"a-link-light"}
          >
            política de privacidad
          </a>
          <br />
        </label>
        <input type="checkbox" name="policy" id="policy" value="Sí" required />
      </div>

      <div className="m-contact-form__submit">
        {!props.success && (
          <button type="submit" className={"a-button a-button--secondary m-contact-form__button"}>
            Enviar formulario {props.submitting && <span className="a-spinner"> {sync_svg} </span>}
          </button>
        )}
      </div>
      {props.fail && (
        <div className="a-fail">
          Ha ocurrido un <b>error</b>, puedes solicitar la información mandando un correo a{" "}
          <a href={`mailto:${email}`} target={"_blank"} rel={"noopener noreferrer nofollow"}>
            {email}
          </a>{" "}
        </div>
      )}
      {props.success && <div className="a-success">¡Gracias! Mensaje enviado</div>}
    </form>
  );
};
