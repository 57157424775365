import * as React from "react";
// Remember that all const are suffix _svg

const close_svg = (
  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.29 19.29">
    <path
      fill={"#fff"}
      d="M1.61,19.29A1.61,1.61,0,0,1,.47,16.55l6.9-6.9L.47,2.74,2.74.47l6.91,6.9,6.9-6.9a1.61,1.61,0,1,1,2.27,2.27l-6.9,6.91,6.9,6.9-2.27,2.27-6.9-6.9-6.91,6.9A1.56,1.56,0,0,1,1.61,19.29Z"
    />
  </svg>
);

export { close_svg };
