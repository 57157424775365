import "./modal-form.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import withForm from "../Forms/withForm";
import { close_svg } from "./elements_modal.jsx";

interface Props extends PropsWithChildren<any> {
  title: string;
  is_active: any;
  form: any;
  from?: string;
}

export const ModalForm: React.FC<Props> = (props: Props) => {
  const ModalWithFrom = withForm(props.form, props.from);
  const { site } = useStaticQuery(graphql`
    query emailInModal {
      site {
        siteMetadata {
          email
        }
      }
    }
  `);
  const { email } = site.siteMetadata;
  return (
    <div className="o-modal-form" id="contact">
      <div className={"m-modal-form__container"}>
        <div className={"o-modal-form__flex"}>
          <p className={"h2 a-modal-form__title"}>{props.title}</p>
          <span
            onClick={() => {
              props.is_active(false);
            }}
            className={"a-close"}
          >
            {close_svg}
          </span>
        </div>
        <ModalWithFrom />
        <div className="o-modal-form__contact">
          <p>
            Puedes enviar un correo a{" "}
            <a href={`mailto:${email}`} target={"_blank"} rel={"noopener noreferrer nofollow"}>
              {email}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
