import * as React from "react";

import useForm from "../../hooks/useForm";

function withForm(Form, from?: string) {
  const { handleSubmit, submitting, success, fail } = useForm();
  return class x extends React.Component {
    from = "píe de página";
    constructor(props) {
      super(props);
      if (from) {
        this.from = from;
      }
    }
    render() {
      return <Form {...{ handleSubmit, submitting, success, fail, from: this.from }} />;
    }
  };
}

export default withForm;
