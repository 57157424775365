import { useState } from "react";

import { pushDatalayer } from "../components/GTM/BannerCookies";

const useForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value.toString()));
    fetch("/.netlify/functions/send-email", {
      method: "POST",
      body: JSON.stringify({
        ...object,
      }),
    })
      .then((e) => {
        if (e.ok) {
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(true);
          pushDatalayer(`${data.get("form-name").toString()}`);
        } else {
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(false);
          setFail(true);
        }
      })
      .catch(() => {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        setFail(true);
      });
  };
  return {
    handleSubmit,
    submitting,
    submitted,
    success,
    fail,
  };
};

export default useForm;
