import "./navbar.scss";

import React from "react";

import NavbarLinks from "../navbarLinks";

const Navbar = () => {
  return (
    <div className="wrapper">
      <NavbarLinks desktop />
    </div>
  );
};

export default Navbar;
